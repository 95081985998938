<template>
  <div class="main-news">
    <div class="nav">
      <div
        class="nav-back"
        @click="$emit('navBack')"
      >媒体报道</div>
      <img src="@/assets/img/pc/newsInfo/arrow_news.png">
      <p>{{limitWords(this.navTitle)}}</p>
    </div>
    <div
      class="news-details"
      v-if="navTitle"
    >
      <VHTML>{{showNews.html}}</VHTML>
    </div>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'
import { Component as VHTML } from '@staszek998/v-html'
export default {
  data () {
    return {
      showNews: '',
      navTitle: this.newTitle,
      newsMap: {
        // '': {
        //   html: `<h1></h1>
        //     <h3></h3>
        //     <p></p>
        //     <p></p>
        // },
        '未讯科技迈入“厚积薄发”新阶段,上下求索不断提升创新力': {
          html: `<h1>未讯科技迈入“厚积薄发”新阶段,上下求索不断提升创新力</h1>
            <h3>2022/11/25</h3>
            <p>2022年9月，浙江未讯科技有限公司及其旗下信贷品牌“易借速贷”迎来了五周年庆。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，易借速贷五年来已与多家银行以及持牌金融机构展开深度合作，并加入中国互联网金融协会，主动积极拥抱监管，不断探索创新方式，服务金融机构数字化升级。2022年初，更是把服务“新市民”当做新一年的重要使命。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>
          `
        },
        '新时代新征程，易借速贷助力“普惠金融”新篇章': {
          html: `<h1>新时代新征程，易借速贷助力“普惠金融”新篇章</h1>
            <h3>2022/10/21</h3>
            <p>2022年是中国向第二个百年奋斗目标迈进的历史交汇点, 具有非凡意义。作为中国互联网金融协会会员单位，浙江未讯科技有限公司也在积极响应国家号召，主动积极拥抱监管，不断探索创新方式，服务金融机构数字化升级。2022年初，更是把服务“新市民”当做新一年的重要使命。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>
          `
        },
        '易借速贷砥砺前行，五年探索，不辱使命': {
          html: `<h1>易借速贷砥砺前行，五年探索，不辱使命</h1>
            <h3>2022/9/29</h3>
            <p>2022年9月，浙江未讯科技有限公司及其旗下信贷品牌“易借速贷”迎来了五周年庆。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，易借速贷五年来已与多家银行以及持牌金融机构展开深度合作，并加入中国互联网金融协会，主动积极拥抱监管，不断探索创新方式，服务金融机构数字化升级。2022年初，更是把服务“新市民”当做新一年的重要使命。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>
          `
        },
        '易借速贷近期发现冒充平台进行诈骗的“李鬼”APP': {
          html: `<h1>易借速贷近期发现冒充平台进行诈骗的“李鬼”APP</h1>
            <h3>2022/9/28</h3>
            <p>近日，网信办集中打击一批“李鬼”式诈骗APP。易借速贷也收到了一些用户的反馈，个别“李鬼”APP，以人工费、保证金等名义强制收费。首先易借速贷不会强制收取任何费用，下载请认准正规渠道，首选官网、官微。</p>
            <p>易借速贷提醒广大用户：正规借贷机构不会向用户收取如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各类费用。用户唯一需要支付的费用是APP上显示的还款计划。请一定通过正规渠道申请贷款产品。易借速贷的授信额度来自正规金融机构，仅与个人信用资质有关，不受其他第三方因素影响，请勿与任何声称“帮忙借款”的 “内部员工”“第三方代理”等进行联系和交易。</p>
            <p>一旦发生上述情况，您可以第一时间联系客服进行核实，如果您已经向骗子支付了费用请立即报警并留存好相关聊天记录。再次提醒广大用户提高警惕，谨防上当受骗。</p>
            <p>“易借速贷”，是浙江未讯科技有限公司旗下信贷平台。请消费者认准官方正规下载渠道， 切勿点击不明链接。也可以拨打400客服电话进行验证。</p>
            <p>同时提醒广大消费者：请您选择正规贷款平台办理业务，务必妥善保管个人信息，提高个人安全意识，不要轻信任何涉及钱财的各类信息，不给不法分子可乘之机，保护财产安全。</p>
          `
        },
        '守护金融市场秩序，易借速贷提醒广大用户警惕黑产业诈骗、误导！': {
          html: `<h1>守护金融市场秩序，易借速贷提醒广大用户警惕黑产业诈骗、误导！</h1>
            <h3>2022/9/8</h3>
            <p>近年来， “修复征信”“化解债务”“债务优化”“停息挂账”等概念甚嚣尘上，一批所谓的“解债机构”通过各类社交平台被推到借款人面前。事实上，这些 “解债机构”从未曾获得过金融管理部门审批许可，根本不是金融机构组织，只是一般市场主体。而这些所谓债务“专家”“信贷法务咨询师”、“网贷维权律师”不仅无法“雪中送炭”，还包藏祸心，极有可能造成借款人的财产损失以及信用受损。</p>
            <h2>骗取高额服务费涉嫌非法集资</h2>
            <p>债务“专家”承诺可以通过现金分期、以物抵债等方式，实现债权清收、债务代偿。本质却是通过收取高额服务费（甚至达到30%-50%）、咨询费、保证金等方式进行非法集资。不法分子在收取高额费用后，不断利用后续参与人员的缴款资金偿还先前参与人员的返利。但是一旦后续客户减少、投入资金不足，必将出现资金链断裂，导致参与人员血本无归。还有部分代理直接截留套取借款人资金，让消费者参与非法集资项目。</p>
            <p>目前，公安和司法机关已开展定期组织开展专项整治活动，打击相关产业，并搭建行业黑名单制，同业间一致行动，运用司法手段处理非法代理投诉。</p>
            <h2>谨防套路贷陷阱</h2>
            <p>据悉，债务 “专家”先通过各种社交平台发布‘帮助上岸’、‘防爆通讯录’、‘阻止催收’等信息，吸引借款人上钩，再通过制造虚假证明，助其逃过催收。然后，再以虚假证明为把柄，要挟借款人使用他们推荐的套路贷产品。如此一来，借款人无疑将自己推向了一个巨大深渊，债务不减反增。</p>
            <p>银保监会提醒借款人，正确用卡理性消费，明辨真伪谨防上当，通过正规渠道维权。</p>
            <h2>珍惜个人信用，谨防个人信息泄露</h2>
            <p>债务“专家”通过微信群、QQ群等进行虚假宣传，误导、怂恿借款人向监管部门、银行投诉，谎称具备‘代理处置信用卡债务’的资格，提供统一的投诉模板；事实上，由于这些机构无任何资质可言，大部分咨询服务都只是骗局，所以并不能从根本上改变出借人和借款人的关系，无法避免逾期借款人的失信问题，反而拖延偿还信用卡欠款，从而导致消费者需承担逾期滞纳金及罚息，最终造成个人征信系统留有不良记录，得不偿失。</p>
            <p>银保监会提醒消费者，请勿轻易泄露个人信息，谨慎提供身份证、银行卡、联系方式、家庭住址等隐私信息。</p>
            <p>最近，银保监会也下发文件《中国银保监会办公厅关于进一步加强消费金融公司和汽车金融公司投诉问题整治》。该文聚重申将“征信修复、洗白、铲单、征信异议投诉咨询、代理”等行为定性为诈骗，此类诈骗行为将按照“扰乱社会信用秩序”定罪。</p>
            <p>易借速贷愿意同广大借款人一道，维护金融市场秩序，坚决打击违法诈骗行为。</p>`
        },

        '易借速贷：用科技手段助力传统金融行业降本增效': {
          html: `<h1>易借速贷：用科技手段助力传统金融行业降本增效</h1>
            <h3>2022-08-19</h3>
            <p>2022年初，《关于银行业保险业数字化转型的指导意见》正式下发。与此同时，央行等多部门联合印发《金融标准化“十四五”发展规划》，明确了标准化引领金融业数字生态建设、稳步推进金融科技标准建设等；《金融科技发展规划（2022—2025年）》的出台，标志着银行业金融科技从“星星之火”全面迈入“积厚成势”的新阶段。</p>
            <p>金融科技英译为Fintech， 是 Financial Technology 的缩写，可以简单理解成为Finance（金融）+Technology（科技），指通过利用各类科技手段创新传统金融行业所提供的产品和服务，提升效率并有效降低运营成本。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，易借速贷已与多家银行以及持牌金融机构展开深度合作。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>
          `
        },
        '易借速贷：科技创新驱动金融服务发展': {
          html: `<h1>易借速贷：科技创新驱动金融服务发展</h1>
            <h3>2022-08-11</h3>
            <p>作为中国互联网金融协会会员单位，浙江未讯科技有限公司也在积极响应国家号召，拥抱监管，通过隐私安全算法、隐私增强技术等技术手段搭建数据智能体系，主动在自身和助力金融机构数字化转型的实践中实现用户隐私保护。未讯科技团队通过职责联动，建立团队协同制度；创新数据自检工具；完善管理流程等手段实现全程留痕，可追溯；数据不出库，可用不可见；数据模型提炼等。</p>
            <p>未讯科技旗下产品易借速贷已全面接入银行征信系统，并与多家第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，保护用户信息安全。易借速贷期望通过科技+金融的完美结合，为平台所有用户提供普惠、绿色、可持续的金融服务。</p>
            <p>未讯科技自主研发的百凌金科大数据平台，将数字化嵌入产品、渠道和流程中，应用在获客、风控、等各个方面，成为涵盖全业务流程、全生命周期的核心技术系统。同时与国内主流数据方合作，建立开放共享的金融服务生态圈，从信息化向数字化、智能化、生态化迈进。</p>
            <p>成立以来，易借速贷凭借“额度高、放款快、息费低”的特点，赢得了广大客户的广泛认可，助力众多用户缓解资金难题，获得更便捷、快速、可获得的金融服务。真正响应了银保监会与人民银行发布的《关于加强新市民金融服务工作的通知》。为满足新市民在创业、就业、住房、教育、医疗、养老等方面的多元化金融服务需求而努力。众多新市民群体的金融需求具有小额分散特征，且个人信用记录未必完善，银行只有提供借助大数据分析与各类智能技术，通过更多维度重新勾画他们的信用特征同时设计针对性的金融产品，才能满足真正实现“服务好新市民”的目标。 易借速贷能够根据用户的需求进行精准匹配，致力于成为受用户欢迎，受伙伴信任的金融科技平台。</p>
          `
        },
        '易借速贷反诈专家提醒：谨防“李鬼”APP诈骗': {
          html: `<h1>易借速贷反诈专家提醒：谨防“李鬼”APP诈骗</h1>
            <h3>2022-07-21</h3>
            <p>近日，网信办集中打击一批“李鬼”式诈骗APP。易借速贷也收到了一些用户的反馈，个别“李鬼”APP，以人工费、保证金等名义强制收费。首先易借速贷不会强制收取任何费用，下载请认准正规渠道，首选官网、官微。</p>
            <p>易借速贷提醒广大用户：正规借贷机构不会向用户收取如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各类费用。用户唯一需要支付的费用是APP上显示的还款计划。请一定通过正规渠道申请贷款产品。易借速贷的授信额度来自正规金融机构，仅与个人信用资质有关，不受其他第三方因素影响，请勿与任何声称“帮忙借款”的 “内部员工”“第三方代理”等进行联系和交易。</p>
            <p>一旦发生上述情况，您可以第一时间联系客服进行核实，如果您已经向骗子支付了费用请立即报警并留存好相关聊天记录。再次提醒广大用户提高警惕，谨防上当受骗。</p>
            <p>“易借速贷”，是浙江未讯科技有限公司旗下信贷平台。请消费者认准官方正规下载渠道， 切勿点击不明链接。</p>
            <p>同时提醒广大消费者：请您选择正规贷款平台办理业务，务必妥善保管个人信息，提高个人安全意识，不要轻信任何涉及钱财的各类信息，不给不法分子可乘之机，保护财产安全。</p>
          `
        },
        '易借速贷：坚决贯彻落实国家发布的金融科技规范健康发展工作方案': {
          html: `<h1>易借速贷：坚决贯彻落实国家发布的金融科技规范健康发展工作方案</h1>
            <h3>2022-06-23</h3>
            <p>6月22日，中央全面深化改革委员会第二十六次会议，审议通过了《强化大型支付平台企业监管促进支付和金融科技规范健康发展工作方案》。</p>
            <p>会议强调，要推动大型支付和金融科技平台企业回归本源，健全监管规则，补齐制度短板，保障支付和金融基础设施安全，防范化解系统性金融风险隐患等。要保护金融消费者合法权益，强化平台企业反垄断、反不正当竞争监管，加强平台企业沉淀数据监管，规制大数据杀熟和算法歧视。</p>
            <p>作为中国互联网金融协会会员单位，浙江未讯科技有限公司也在积极响应国家号召，拥抱监管，通过隐私安全算法、隐私增强技术等技术手段搭建数据智能体系，主动在自身和助力金融机构数字化转型的实践中实现用户隐私保护。未讯科技团队通过职责联动，建立团队协同制度；创新数据自检工具；完善管理流程等手段实现全程留痕，可追溯；数据不出库，可用不可见；数据模型提炼等。</p>
            <p>未讯科技旗下产品易借速贷已全面接入银行征信系统，并与多家第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，保护用户信息安全。易借速贷期望通过科技+金融的完美结合，为平台所有用户提供普惠、绿色、可持续的金融服务。</p>
            <p>未讯科技自主研发的百凌金科大数据平台，将数字化嵌入产品、渠道和流程中，应用在获客、风控、等各个方面，成为涵盖全业务流程、全生命周期的核心技术系统。同时与国内主流数据方合作，建立开放共享的金融服务生态圈，从信息化向数字化、智能化、生态化迈进。</p>
            <p>成立以来，易借速贷凭借“额度高、放款快、息费低”的特点，赢得了广大客户的广泛认可，助力众多用户缓解资金难题，获得更便捷、快速、可获得的金融服务。真正响应了银保监会与人民银行发布的《关于加强新市民金融服务工作的通知》。为满足新市民在创业、就业、住房、教育、医疗、养老等方面的多元化金融服务需求而努力。众多新市民群体的金融需求具有小额分散特征，且个人信用记录未必完善，银行只有提供借助大数据分析与各类智能技术，通过更多维度重新勾画他们的信用特征同时设计针对性的金融产品，才能满足真正实现“服务好新市民”的目标。 易借速贷能够根据用户的需求进行精准匹配，致力于成为受用户欢迎，受伙伴信任的金融科技平台。</p>
          `
        },
        '易借速贷：坚守数字化转型中的隐私保护': {
          html: `<h1>易借速贷：坚守数字化转型中的隐私保护</h1>
            <h3>2022-06-09</h3>
            <p>2015年，五中全会的“十三五”规划建议提出：“实施国家大数据战略，推进数据资源开放共享。”数字化时代的到来，不仅意味着新的发展机遇，也让数据隐私保护成为了新的难题。</p>
            <p>国际经合组织在1985年发布《越境信息流规则》。2000年美国为与欧洲共同构建并签署了《安全港隐私原则》。2018年，欧盟发布《通用数据保护条例。2021年8月20日，全国人大常委会通过《个人信息保护法》，我国个人信息处理正式进入有法可依的新时代。</p>
            <p>作为中国互联网金融协会会员单位，浙江未讯科技有限公司也在积极响应国家号召，拥抱监管，通过隐私安全算法、隐私增强技术等技术手段搭建数据智能体系，主动在自身和助力金融机构数字化转型的实践中实现用户隐私保护。</p>
            <p>面对传统的安全技术、冗长的合规流程、人工的处理方式等，数字化转型中的隐私保护面临重重困难：</p>
            <p>1、国际、国家、行业层面都尚无可落地的隐私数据分级分类标准，企业级分级分类标准的制定缺乏依据；</p>
            <p>2、无法充分全面地掌握和识别企业各个条线收集、存储、处理和共享的隐私数据；</p>
            <p>3、数据责任主体不明确，由于隐私数据没有明确的责任主体，导致数据一旦被采集并存入信息系统，其他业务需求便可以无需审批的复用、分享和转移；</p>
            <p>4、隐私风险评估的实现方式较为传统，无法跟上业务部门的数据需求和监管部门的监管要求；</p>
            <p>5、数据的处理及共享受阻，导致无法更好挖掘数据的隐藏价值；</p>
            <p>6、用户隐私需求的响应大量依赖于人工处理而非自动化的流程等。</p>
            <p>基于以上困难点，未讯科技团队通过职责联动，建立团队协同制度；创新数据自检工具；完善管理流程等手段实现全程留痕，可追溯；数据不出库，可用不可见；数据模型提炼等。</p>
            <p>浙江未讯科技有限公司旗下信贷品牌易借速贷基于人工智能和大数据风控应用能力，与银行等金融机构伙伴合作，提供面向大众的个人信贷服务，具有申请简便、审批快、额度高、息费低、到账快等特点。易借速贷能够根据用户的需求进行精准匹配，致力于成为受用户欢迎，受伙伴信任的金融科技平台。</p>
          `
        },
        '易借速贷提醒：非法投诉、逃废债，害人害己，国家重拳出击，严打“债闹”': {
          html: `<h1>易借速贷提醒：非法投诉、逃废债，害人害己，国家重拳出击，严打“债闹”</h1>
            <h3>2022年4月18日 11:04</h3>
            <p>近日，中办、国办、发改委多部门接连发文，对假借“征信修复”“征信洗白”等名义招摇撞骗，通过虚假宣传、教唆无理申诉、材料造假、恶意投诉等手段骗取钱财或个人信息，严重扰乱市场秩序的市场主体以零容忍态度重拳打击。央视等重磅媒体也密集曝光十余省市合力围剿“债闹”，打击非法代理投诉，监管层层加码。</p>
            <p>据不完全统计，2021年，市面上新增的各类信用修复公司、代理维权机构、反催收中介、个人债务重组公司等，是疫情前的10倍之多。保守估计黑产人员煽动教唆债闹人员数量有上千万，造成财产损失达数百亿级</p>
            <p>2021年5月，湖北宜昌一家反催收机构以为信用卡持卡人解决债务问题为名收取高额服务费，却因无法提供服务终被告上了法庭，团伙成员分别被判处2-11年有期徒刑和罚金。</p>
            <p>近期，易借速贷也发现了部分用户存在被“黑中介”怂恿煽动的情况。还有一些所谓的“中介”公司，扣押用户的身份证、手机等私人物品，收取服务费、渠道费、违约金等。</p>
            <p>易借速贷提醒广大用户：正规借贷机构不会向用户收取如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各类费用。用户唯一需要支付的费用是APP上显示的还款计划。请一定通过正规渠道申请贷款产品。易借速贷的授信额度来自正规金融机构，仅与个人信用资质有关，不受其他第三方因素影响，请勿与任何声称“帮忙借款”的 “内部员工”“第三方代理”等进行联系和交易。</p>
            <p>一旦发生上述情况，您可以第一时间联系客服进行核实，如果您已经向骗子支付了费用请立即报警并留存好相关聊天记录。再次提醒广大用户提高警惕，谨防上当受骗。</p>
            <p>请消费者认准官方正规下载渠道（正规应用商城、官方微信公众号、官方网站）， 切勿点击不明链接。</p>
            <p>同时提醒广大消费者：请您选择正规贷款平台办理业务，务必妥善保管个人信息，提高个人安全意识，不要轻信任何涉及钱财的各类信息，不给不法分子可乘之机，保护财产安全。</p>
          `
        },
        '易借速贷：赋能服务新市民，大数据分析+金融反欺诈成当务之急': {
          html: `<h1>易借速贷：赋能服务新市民，大数据分析+金融反欺诈成当务之急</h1>
            <h3>2022年4月6日 17:49</h3>
            <p>3月初，银保监会与人民银行发布《关于加强新市民金融服务工作的通知》，通知提出要满足新市民在创业、就业、住房、教育、医疗、养老等方面的多元化金融服务需求。而做好这件事，科技赋能金融成为必然。众多新市民群体的金融需求具有小额分散特征，且个人信用记录未必完善，银行只有提供借助大数据分析与各类智能技术，通过更多维度重新勾画他们的信用特征同时设计针对性的金融产品，才能满足真正实现“服务好新市民”的目标。</p>
            <p>同时，多位金融业内人士看来，要服务好新市民群体，另一项重要工作是持续增强他们的金融反欺诈意识。究其原因，一是众多新市民的金融反欺诈意识相对较低，容易给不法分子有机可乘；二是一些不法分子针对进城务工人员与刚就业年轻人的生活特点，设计专门的欺诈套路诱骗他们上当。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，未讯科技已与多家银行以及持牌金融机构展开深度合作，并时刻关注行业发展趋势，积极响应国家政策号召。因此，对于赋能服务新市民，未讯科技也将坚定地做出行业表率。</p>
            <p>未讯科技利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，未讯科技利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>在反金融欺诈方面，未讯科技联手警方、用户共同打造反诈“屏障”，防范金融诈骗。2021年，为打击防范电信网络诈骗，霖梓控股从创新技术手段、宣传方式，到客服引导、法律援助，实现对用户多渠道、全方位的支持和帮助。</p>
            <p>一年以来，共计百余名用户向霖梓控股反馈涉诈案件，单笔涉诈金额从几百元到数万元不等。霖梓控股帮助客户及时止损280万余元；携手相关机构将176个仿冒安装包列入黑名单，并在浏览器设置拦截；向公安机关举报反馈涉嫌假冒网址46家；成功向用户推广 “国家反诈中心APP” 并下载142次；第一时间协助用户留存证据，当地报警245例；联合电信部门，对473个涉嫌诈骗号码进行标记。</p>
            <p>企业内部由风控部门牵头，客服、运营、品牌参与成立专门的反诈骗工作组，并定期形成反诈骗报告。同时，不断夯实基础，优化模型，借助反欺诈风控模型、策略，有效识别出疑似被欺诈的用户，系统自动向用户推送、弹窗、短信等，及时介入，提醒客户仔细辨别，谨防诈骗。</p>
          `
        },
        '易借速贷：跟紧国务院金融委释放的重磅信号': {
          html: `<h1>易借速贷：跟紧国务院金融委释放的重磅信号</h1>
            <h3>2022年3月21日 13:56</h3>
            <p>3月16日，国务院金融稳定发展委员会召开专题会议，会议提到了平台经济治理。会议要求，有关部门要按照市场化、法治化、国际化的方针完善既定方案，坚持稳中求进，通过规范、透明、可预期的监管，稳妥推进并尽快完成大型平台公司整改工作，红灯、绿灯都要设置好，促进平台经济平稳健康发展，提高国际竞争力。</p>
            <p>在国务院新闻办公室3月2日召开的新闻发布会上，银保监会主席郭树清也谈及了蚂蚁集团等14家涉金融互联网平台整改进度，强调坚决遏制资本在金融领域无序扩张。金融创新必须在审慎监管前提下进行强化金融反垄断和反不正当竞争，规范持牌机构与互联网平台公司业务合作，维护市场秩序。</p>
            <p>国务院金融委会议定调后，3月16日，央行、银保监会也接连强调传达学习国务院金融委第51次专题会议精神，研究部署央行系统贯彻落实工作。</p>
            <p>央行方面指出，货币政策要主动应对，新增贷款要保持适度增长，大力支持中小微企业，坚定支持实体经济发展，保持经济运行在合理区间。稳妥推进并尽快完成大型平台公司整改工作，促进平台经济健康平稳发展，提高国际竞争力。</p>
            <p>银保监会专题会议则强调，要指导银行保险机构从大局出发，坚定支持实体经济发展。满足市场主体合理融资需求，加大融资供给，新增贷款要保持适度增长。促进中小微企业融资增量、扩面、降价。各部门和派出机构要鼓励银行保险机构创新支持国家科技研究，更好服务关键核心技术攻关企业和“专精特新”企业。提升金融服务乡村振兴质效，助力“新市民”在城镇安居创业。</p>
            <p>易借速贷目前已实现了以技术助力金融机构全流程的数字化升级，这一结果来自未讯科技持续不断的技术投入。易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在此前，易借速贷已与多家金融机构展开深度合作；同时，与第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库。我们期望通过科技+金融的完美结合，为所有用户提供便捷、安心的信贷服务。</p>
            <p>数字经济作为构建新发展格局的重要战略方向，将是今后金融行业发展的重要源泉，科技在这一进程中将扮演着十分重要的角色。易借速贷母公司未讯科技将积极响应国家十四五号召，继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级，推动数字金融建设。</p>
          `
        },
        '未讯科技：聚焦两会，助力金融行业数字化转型': {
          html: `<h1>未讯科技：聚焦两会，助力金融行业数字化转型</h1>
            <h3>2022年3月10日 17:57</h3>
            <p>2022年是党的二十大召开之年，也是中国进入全面建设社会主义现代化国家、向第二个百年奋斗目标进军新征程的重要一年。3月4日-3月11日，“两会”在首都北京如期召开。这一年里，金融作为经济活水该如何“引用”发挥最大效能，引发了热烈讨论。</p>
            <p>《看懂经济》邀请行业人士唐爽就未来金融科技这个行业在国内应该怎么走？作出解答。唐爽认为：金融科技行业一定会更快、更好地发展。在未来，金融科技企业要持续助力金融机构数字化转型。金融机构在前期已经基本完成了信息化、系统化的转变，需要进入数字化转型的新阶段。数字化的基本特征就是以数据驱动为基础，推动金融机构营销、风控、内部管理等方面的更加自动化、智能化，目前金融机构的竞争更加白热化，息差缩小、金融脱媒、获客活客难、信用风险高、内部协同成本高等问题正在困扰更多的金融机构，如何通过数字化的方式促进这些问题的解决是金融科技未来的重点方向。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，未讯科技已与多家银行以及持牌金融机构展开深度合作。</p>
            <p>在安全性方面，未讯科技采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，未讯科技严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，未讯科技利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>
          `
        },
        '易借速贷安全专家：任何要求打款到私人账户的都是骗子': {
          html: `<h1>易借速贷安全专家：任何要求打款到私人账户的都是骗子</h1>
            <h3>2022年3月4日 16:20</h3>
            <p>近期，易借速贷接到很多用户反馈，有诈骗分子冒充易借速贷的工作人员，以“利率调整”、“结算错误”等理由要求用户将钱打到指定银行卡，然后再将钱转走。还有一些所谓的“中介”公司，扣押用户的身份证、手机等私人物品，收取服务费、渠道费、违约金等。</p>
            <p>易借速贷提醒广大用户：正规借贷机构不会向用户收取如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各类费用。用户唯一需要支付的费用是APP上显示的还款计划。请一定通过正规渠道申请贷款产品。易借速贷的授信额度来自正规金融机构，仅与个人信用资质有关，不受其他第三方因素影响，请勿与任何声称“帮忙借款”的 “内部员工”“第三方代理”等进行联系和交易。</p>
            <p>一旦发生上述情况，您可以第一时间联系客服进行核实，如果您已经向骗子支付了费用请立即报警并留存好相关聊天记录。再次提醒广大用户提高警惕，谨防上当受骗。</p>
            <p>“易借速贷”，是浙江未讯科技有限公司旗下信贷平台，官方网址为www.blingfintech.com，官方客服电话为400-777-8802，投诉电话：0571-87608950。请消费者认准官方正规下载渠道（正规应用商城、官方微信公众号、官方网站）， 切勿点击不明链接。</p>
            <p>同时提醒广大消费者：请您选择正规贷款平台办理业务，务必妥善保管个人信息，提高个人安全意识，不要轻信任何涉及钱财的各类信息，不给不法分子可乘之机，保护财产安全。</p>
          `
        },
        '易借速贷：响应十四五号召，加码数字金融建设': {
          html: `<h1>易借速贷：响应十四五号召，加码数字金融建设</h1>
            <h3>2022年2月17日 11:23</h3>
            <p>截至目前，全国已有福建、广东、陕西、上海、深圳等20余个省市“十四五”金融改革规划登台亮相。从规划来看，各省市都提出了金融业增加值、增加值占GDP 比重、银行业存贷款等预期量化目标，以提升金融业贡献度。这就需要有规模的金融市场主体和完善的金融体系等。</p>
            <p>易借速贷目前已实现了以技术助力金融机构全流程的数字化升级，这一结果来自未讯科技持续不断的技术投入。易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在此前，易借速贷已与多家金融机构展开深度合作；同时，与第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库。我们期望通过科技+金融的完美结合，为所有用户提供便捷、安心的信贷服务。</p>
            <p>数字经济作为构建新发展格局的重要战略方向，将是今后金融行业发展的重要源泉，科技在这一进程中将扮演着十分重要的角色。易借速贷母公司未讯科技将积极响应国家十四五号召，继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级，推动数字金融建设。</p>`
        },
        '易借速贷：坚定践行“数据驱动”的企业核心价值观': {
          html: `<h1>易借速贷：坚定践行“数据驱动”的企业核心价值观</h1>
            <h3>2022年2月16日 09:41</h3>
            <p>近日，《金融科技发展规划(2022-2025年)》(以下简称《规划》)正式印发，这是央行发布的金融科技发展第二阶段规划。与三年前的《金融科技(FinTech)发展规划(2019-2021年)》相比，新版《规划》更加强调了“数据”在金融科技发展中的重要地位。</p>
            <p>根据新版《规划》，提出新时期金融科技发展指导意见，明确金融数字化转型的总体思路、发展目标、重点任务和实施保障。央行未来四年的金融科技发展原则是“数字驱动、智慧为民、绿色低碳、公平普惠”；发展基础是加强金融数据要素应用；发展目标是深化金融供给侧结构性改革；发展主线是加快金融机构数字化转型、强化金融科技审慎监管。多位业内专家指出，《规划》将数据驱动金融科技发展提升至重要位置，并为如何充分发挥数据要素潜能制定了工作指南。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，未讯科技已与多家银行以及持牌金融机构展开深度合作。</p>
            <p>在安全性方面，未讯科技采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，未讯科技严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，未讯科技利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>`
        },
        '易借速贷出席2022零壹智库数字经济年会，共论商业银行数字化转型': {
          html: `<h1>易借速贷出席2022零壹智库数字经济年会，共论商业银行数字化转型</h1>
            <h3>2022年1月17日 10:09</h3>
            <p>2022年1月13日，以“数字发展：构建新格局、构筑新优势”为主题的2022零壹智库数字经济年会在上海隆重召开。易借速贷母公司浙江未讯科技有限公司受邀出席。</p>
            <p>会议中，交通银行发展研究部资深专家周昆平发表演讲，他提到：商业银行全面布局数字化战略转型，有三大特征，其中很重要的一点是：向金融科技公司学习并寻求合作。他认为商业银行牵手互联网企业，实现战略伙伴技术能力、客户群体、价值主张与银行核心业务、未来发展方向相契合，并带来协同效应。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，易借速贷已与多家银行以及持牌金融机构展开深度合作。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>`
        },
        '易借速贷助力金融行业新生态': {
          html: `<h1>易借速贷助力金融行业新生态</h1>
            <h3>2021年12月20日 11:27</h3>
            <p>12月16日消息，由新浪财经主办的“第14届金麒麟金融峰会”于12月13日-17日隆重举行。中国互联网金融协会会长、中国人民银行原副行长李东荣在论坛上发表了题为《科学妥善应对金融机构数字化转型的几个不平衡问题》的主旨演讲。李东荣表示，随着消费互联网“红利效应”的递减和产业互联网“蓝海效应”的增强，金融机构应审时度势，注重将金融科技应用的重点从拓渠道、扩规模转向优流程、强运营，实现更加全面协调的数字化转型。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>`
        },
        '工信部发布金融科技利好消息，易借速贷顺势而为': {
          html: `<h1>工信部发布金融科技利好消息，易借速贷顺势而为</h1>
            <h3>2021年12月9日 15:59</h3>
            <p>近日，工信部正式复函支持苏州创建区块链发展先导区。这意味着苏州成为全国首个正式获批创建的国家区块链发展先导区。值得注意的是，区块链专栏明确落脚点在金融科技行业。要求推动区块链与金融科技创新协同发展，着力提高金融服务实体经济能力，争创国家级数字金融产业集聚区、国家级区块链发展先行区。发挥南京作为国家科技体制综合改革试点城市及金融科技应用试点城市的优势，鼓励数字金融产业研究院等机构积极探索区块链平台建设和技术应用场景。</p>
            <p>未讯科技作为中国互联网金融协会成员之一，致力于以科技推动金融服务业的数字化升级，主动拥抱监管，严格执行国家相关法规和政策，积极参加行业交流，促进行业自律。未讯科技深刻理解恪守底线对于长期稳定经营的重要性。多年来，未讯科技始终活跃在金融科技领域，与广大的合作伙伴保持着长期、共赢的合作状态。</p>
            <p>未讯科技旗下产品易借速贷已全面接入银行征信系统，并与多家第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，保护用户信息安全。易借速贷期望通过科技+金融的完美结合，为平台所有用户提供普惠、绿色、可持续的金融服务。</p>
            <p>易借速贷自主研发的百凌金科大数据平台，将数字化嵌入产品、渠道和流程中，应用在获客、风控、等各个方面，成为涵盖全业务流程、全生命周期的核心技术系统。同时与国内主流数据方合作，建立开放共享的金融服务生态圈，从信息化向数字化、智能化、生态化迈进。</p>`
        },
        '警企联动反诈骗，防微杜渐筑防线': {
          html: `<h1>警企联动反诈骗，防微杜渐筑防线</h1>
            <h3>2021年11月19日 19:15</h3>
            <p>2021年11月19日，由萧山公安分局市北派出所指导、杭州湾信息港主办、浙江霖梓控股有限公司承办的“警企联动，反诈同行”——反诈主题宣讲会在杭州湾信息港顺利召开。本次活动旨在提高信息港园区各企业及员工的防范电信网络诈骗意识，保护企业资金和企业员工个人资金的安全。</p>
            <div class="news-bg-img"  id="newIcon14-1"></div>
            <p>2021年，全国上下掀起了一场全民反诈浪潮。面对层出不穷的电信网络诈骗手段和不断攀升的受骗数据，企业、员工怎样才能一眼识破骗局，避免损失，打造一堵坚不可摧的反诈“防火墙”？为了回答这个问题，浙江霖梓控股有限公司携手萧山市北派出所和信息港园区，为园区企业开展防范电信网络诈骗培训，打击治理电信网络新型违法犯罪。</p>
            <div class="news-bg-img"  id="newIcon14-2"></div>
            <p>培训中，民警针对非法集资、非法股权投资、虚拟货币投资、消费返利等投资理财诈骗的常用手段；以及网络招嫖、网络购物、网络刷单、视频裸聊、冒充QQ好友等诈骗手段，结合最具代表性的辖区具体案例，对诈骗案的发生、嫌疑人实施诈骗的手法进行全方面的剖析；同时，简明扼要地向到场观众传授防诈骗技巧，指导如何保存证据、有效报警等。</p>
            <div class="news-bg-img"  id="newIcon14-3"></div>
            <p>现场，浙江霖梓控股有限公司的企业代表还就企业的反诈成果进行汇报，为参会人员提供了全新的思路，也向社会大众表明：企业在全民反诈潮流中扮演着不可或缺的角色。2021年，共计百余名用户向霖梓控股反馈涉诈案件，单笔涉诈金额从几百元到数万元不等。霖梓控股帮助客户及时止损560万余元；携手相关机构将563个仿冒安装包列入黑名单，并在浏览器设置拦截；向公安机关举报反馈涉嫌假冒网址143家；成功向用户推广“国家反诈中心APP”并下载642次；第一时间协助用户留存证据，当地报警345例；联合电信部门，对473个涉嫌诈骗号码进行标记。</p>
            <div class="news-bg-img"  id="newIcon14-4"></div>
            <p>活动中，参会人员对公安部门推出的防电信诈骗神器“金钟罩”反诈系统尤为关注。这个系统汇集了海量的电信诈骗数据，会对注册人及其添加的家人实施精准的防诈保护。成功注册后，当诈骗分子企图通过多种通讯方式联系您时，终结诈骗服务号的后台将会自动为您推送预警消息，提示您有可能已经成为诈骗分子实施诈骗的目标对象，也可以有效保护企业资金和企业员工个人资金安全。</p>
            <p>警方提示：谨记天上不会掉馅饼，馅饼下面必有陷阱。让我们警企携手，共筑防线！</p>`
        },
        '未讯科技出席第一届中国信用经济发展峰会暨2021第三届数字信用与风控年会': {
          html: `<h1>未讯科技出席第一届中国信用经济发展峰会暨2021第三届数字信用与风控年会</h1>
            <h3>2021年11月01日 21:40</h3>
            <div class="news-bg-img"  id="newIcon13"></div>
            <p>日前，“第一届中国信用经济发展峰会暨2021第三届数字信用与风控年会”在深圳隆重召开。本次峰会聚焦征信、大数据与风控实务，邀请政府、学术界、金融界、技术界专家和企业高管共同参与，助力数字经济行业创新发展，探寻信用经济与风控的新征程。易借速贷母公司浙江未讯科技有限公司出席会议并与参会嘉宾展开广泛沟通和交流。</p>
            <p>本次峰会由零壹财经·零壹智库主办，深圳信用促进会、中国科技体制改革研究会数字经济研究小组、全联并购公会信用管理专业委员会联合主办。</p>
            <h2>个人征信业与个人数据产业发展</h2>
            <p>全联并购公会信用管理委员会顾问、人民银行征信中心原副主任汪路表示，未来会有更多的非信贷的数据应用到个人信用乃至整个信用主体的判断、管理上来，超出信贷乃至经济信用交易的数据拿来作为判断个人信用状况的补充数据，应当需要法律或监管者明确规范。</p>
            <h2>信用经济发展的新图景</h2>
            <p>信用建设服务实体经济，助力经济高质量发展已经成为了社会的共识。在本次峰会上，深圳市信用促进会联合金融壹账通共同发起成立了“深圳市信用促进会信用经济工作委员会”。深圳市信用促进会信用经济工作委员会将总结信用经济试验区创建经验，整合信用经济相关产业资源，积极探索信用经济所需的标准、技术、产品和平台体系，立足湾区、服务全国，助力全国信用经济体系的建设和发展。</p>
            <h2>数据监管下的数字风控新模式</h2>
            <p>深圳市互联网金融协会秘书长曾光表示，中小金融机构要依靠自身的力量进行数据治理、风险防控，要夯实风控、数据治理能力；大数据平台可以寻求向技术输出转变，将以往的数据输出转换成系统输出；处理数据核心是实行分类管理和敏感信息“同意”，数字风控应依据信息分类开发科学决策引擎；根据数据处理的具体场景，采取差异化数据风控自动化决策，特别是银行金融机构要构建多样性业务场景；非征信牌照的风控数据公司成本更高，流程更复杂。</p>
            <p>未讯科技坚信，风控是平台的核心，无论业务拓展、运营效率的提升，还是风险管理改进等方面的突破都离不开完善的风控体系。易借速贷在借贷流程各个环节制定了安全严苛的审核门槛，采用顶级信息安全技术传输加密，专业的多级防火墙，符合国际顶级安全PCI 标准，使用技术预防恶意骗贷。在互联网大数据与AI智能科技的支撑下，易借速贷在反欺诈，信用评级，和降低成本提升效率方面效果显著。</p>
            <p>在金融与科技深度融合的过程中，风控体系也会越来越完善，易借速贷将持续强化前沿技术在智能风控体系中的应用，以安全为基，筑建信贷风控防护墙。未来，易借速贷将与金融机构以及广大互联网金融企业携手，为金融机构信贷业务数字化转型助力，共同迎接互联网金融有序化、规范化、可持续化时代的到来。</p>`
        },
        '易借速贷：赋能区域性银行数字化转型': {
          html: `<h1>借速贷：赋能区域性银行数字化转型</h1>
            <h3>2021年09月06日 11:04</h3>
            <p>去年，《中共中央 国务院关于构建更加完善的要素市场化配置体制机制的意见》（以下简称《意见》）中，将“数据”作为一种新型生产要素，首次与其他传统要素并列成为第五大生产要素。数据资源作为生产要素，是数字经济时代银行转型的关键抓手。而相关研究报告也证实了这一点。</p>
            <p>2020年12月， IDC发布的《中国区域性银行数字化转型白皮书》显示，超九成区域性银行已经启动数字化转型工作。其中，大数据成为最受关注的新兴技术之一。但对于区域性银行来说，在云、大数据等技术应用研发和产品采购方面的投入逐年增加，成为制约其数字化转型的重要因素。借助第三方科技服务企业的强大数据基础，赋能区域性银行数字化转型已成为愈来愈受银行欢迎的双赢模式。</p>
            <p>作为一家以“数据驱动”为核心导向，专注于深度学习＋人工智能结合应用的金融科技公司，易借速贷已与多家银行以及持牌金融机构展开深度合作。</p>
            <p>在安全性方面，易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <p>在数字化风控方面，利用大数据技术和先进的分析建模工具，搭建风险管理架构。与中国联通等第三方机构合作增加获取动态数据的渠道，收集相关数据信息提高风险识别能力，增加数字化风险审核流程，提高效率，减少差错率。</p>
            <p>在数字化营销方面，易借速贷利用数字化技术有效拓展客群方位，通过数字挖掘发现客户的行为模式和动态需求，发展长尾末端的金融客户，服务客户，实现普惠金融。通过大数据分析实现精准营销、内容营销、数字化的客户生命周期管理等，借助数字化为客户提供全面金融服务。</p>
            <p>未来，易借速贷将在母公司未讯科技的带领下继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p>`
        },
        '易借速贷发布关于不法分子冒充平台实施诈骗的声明': {
          html: `<h1>易借速贷发布关于不法分子冒充平台实施诈骗的声明</h1>
            <h3>2021年08月26日 23:30</h3>
            <p>近日，浙江未讯科技有限公司旗下信贷平台“易借速贷”（以下简称平台）发现多起不法分子冒充该平台员工或合作渠道进行网络贷款诈骗事件，对平台声誉造成严重影响，对广大用户财产安全造成严重威胁和伤害。</p>
            <p>目前，该平台已将相关证据提交司法机构，并采取业务流程全面排查等措施维护本平台及用户的合法权益。易借速贷称：平台将维权到底，决不允许任何个人和机构侵犯平台及用户权益。同时提醒广大用户提高警惕，谨防上当受骗。</p>
            <div class="news-bg-img" id="newIcon11-1"></div>
            <p>平台声明如下：</p>
            <p>平台APP客户端名称为“易借速贷”，是浙江未讯科技有限公司旗下信贷平台，官方网址为www.blingfintech.com，官方客服电话为400-777-8802，投诉电话：0571-87608950。请消费者认准官方正规下载渠道（正规应用商城、官方微信公众号、官方网站）， 切勿点击不明链接。</p>
            <p>同时提醒广大消费者：请您选择正规贷款平台办理业务，务必妥善保管个人信息，提高个人安全意识，不要轻信任何涉及钱财的各类信息，不给不法分子可乘之机，保护财产安全。</p>
            <p>平台除易借速贷APP上展示的还款计划外，不会以任何理由收取其他附加费用，如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各种费用。如您发现有冒充本平台实施不法行为的机构或个人, 请第一时间拨打官方客服电话400-777-8802 (9:00-21:00)进行核实；若对您造成资金损失，请及时向公安机关报案。</p>
            <p>再次提醒广大消费者提高警惕，谨防上当受骗。</p>`
        },
        '易借速贷母公司未讯科技正式成为中国互联网金融协会会员': {
          html: `<h1>易借速贷母公司未讯科技正式成为中国互联网金融协会会员</h1>
          <h3>2021年08月19日 10:32</h3>
          <p>近日，中国互联网金融协会向浙江未讯科技有限公司（前身为霖梓科技，以下简称未讯科技）颁发了会员证书，宣告未讯科技正式成为协会一员。未讯科技有关负责人表示：“很荣幸加入中国互联网金融协会，未讯科技将自觉遵守中国互联网金融协会的章程，协同全体会员单位一道，提升自身服务品质，践行普惠金融，净化行业环境，促进行业健康发展，为中国互联网金融的发展贡献自身的力量。”</p>
          <div class="news-bg-img" id="newIcon10"></div>
          <p>中国互联网金融协会是由中国人民银行会同银监会、证监会、保监会等国家有关部委组织建立的国家级互联网金融行业自律组织。2015年12月31日，经国务院批准，民政部通知中国互联网金融协会准予成立。协会单位会员包括银行、证券、保险、基金、期货、信托、资产管理、消费金融、征信服务以及互联网支付、投资、理财、借贷等机构,基本覆盖了互联网金融的主流业态和新兴业态。</p>
          <p>中国互联网金融协会是中国最具代表性和影响力的行业自律组织。此次入会反映了业界对未讯科技及易借速贷业务发展的认可。未来，易借速贷将继续探索合规框架下的创新技术和产品模式，优化信息安全技术，改进存储加密机制，严格授权与审计制度，加大数据安全领域的投入，加强与高校的技术合作，并且将自己的数据安全管理和技术经验进行分享和输出，共同推动数据安全产业生态的发展。</p>`
        },
        '易借速贷安全性获认可：平台完成“国家信息系统安全等级保护三级认证”': {
          html: `<h1>易借速贷安全性获认可：平台完成“国家信息系统安全等级保护三级认证”</h1>
            <h3>2021年08月19日 10:27</h3>
            <p>日前，易借速贷母公司浙江未讯科技有限公司（前身为霖梓科技，以下简称未讯科技）荣获由公安部核准颁布的“信息系统安全等级保护三级认证”。该认证意味着未讯科技旗下包括易借速贷在内的产品在物理安全、网络安全等数十个方面达到国家信息等级保护三级认证标准，能够为用户提供可靠、安全的服务。</p>
            <div class="news-bg-img"  id="newIcon9"></div>
            <p>据了解，“信息系统安全等级保护”备案，由国家信息安全监管部门进行监督、检查，主要包含信息保护、安全审计、通信保密等在内的近300项要求，共涉及测评分类73类，要求十分严格。目前，我国将信息系统的安全保护等级分为5个等级，一至五级等级逐级增高。其中，“信息系统安全等级保护三级认证”是国家对非银行机构的最高级认证，属于“监管级别”。通过“信息系统安全等级保护三级认证”，表明企业的信息安全管理能力达到行业最高标准。</p>
            <p>未讯科技作为中国互联网金融协会成员之一，致力于以科技推动金融服务业的数字化升级。作为一家金融科技公司，未讯科技将充分发挥自身的技术优势，携手锡商银行等金融机构合作伙伴为广大用户提供普惠、绿色、可持续的金融服务。</p>
            <p>未讯科技旗下信贷服务品牌——易借速贷，基于人工智能和大数据风控应用能力，提供面向大众的个人信贷服务，具有申请简便、审批快、额度高、息费低、到账快等特点。易借速贷能够根据用户的需求进行精准匹配，根据用户的信用资质，提供5000元到200000元的循环额度。在授信额度内，用户可选择使用现金随借随还、现金分期等业务，解决资金周转问题。</p>`
        },
        '易借速贷：建设数据安全体系，推动行业良性发展': {
          html: `<h1>易借速贷：建设数据安全体系，推动行业良性发展</h1>
          <h3> 2021年06月30日 11: 02</h3>
          <p>第十三届全国人民代表大会常务委员会第二十九次会议表决通过《中华人民共和国数据安全法》，将于2021年9月1日起施行。在《数据安全法》出台之前，缺乏统一标准，数据管理也不够完善。随着近年来，大家对数据安全越来越重视，新的《数据安全法》将成为数据安全的基本法，可以说是对野蛮生长的市场进行的一次规范，其使得相关行业对于存储数据、提供数据、使用数据，或者是用数据再产生数据、再产生决策的时候，有了基本的准则和法律依据，，对各行业都将起到巨大的促进作用。</p>
          <p>易借速贷作为深耕金融科技领域多年的企业，深刻理解数据安全的重要性。事实上，在“发展数字经济”的浪潮下，受到个人信息保护和大数据融合应用的双重驱动，隐私计算迅速崛起，在获客营销、信贷风控和反欺诈等金融领域得到长足的应用。数据安全既要重视隐私保护，又要保证交互，如果数据保护太过严格，难以打通交互，就无法产生价值。因此，应倡导平衡好数据隐私保护和发展数据产业之间的关系。</p>
          <div class="news-bg-img" id="newIcon1"></div>
          <p>易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全无忧。</p>
          <p>易借速贷母公司浙江未讯科技有限公司，作为中国互联网金融协会成员之一，致力于以科技推动金融服务业的数字化升级，自成立以来已与多家第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，不断提升数据安全水平。 隐私保护、数据安全需多方推进。监管层面完善相应的法律法规，构筑中国信息及数据安全领域的法律框架。企业对于隐私保护技术也要有充分的认识，不是有隐私保护技术就完全解决问题，应针对不同应用场景做严格的区分和验证。数据安全是企业的核心发展问题。未来，易借速贷还会加大数据安全领域的投入，加强与高校的技术合作，并且将自己的数据安全管理和技术经验进行分享和输出，共同推动数据安全产业生态的发展。</p>`
        },
        '易借速贷用专业保护消费者合法权益': {
          html: `<h1>易借速贷用专业保护消费者合法权益</h1>
          <h3>2021月06日22 10:46:49</h3>
          <p>近年来，医美行业发展迅速，越来越多年轻人为了更好的展示自己，选择通过医美行业实现变美心愿。与此同时，金融行业也推出了大批美分期、美容贷服务，但由于行业不成熟，各种借贷乱象频繁发生。</p>
          <p>近期，中国互联网金融协会发布《关于规范医疗美容相关金融产品和金融服务的倡议》，针对医美行业过度诱导消费现象，互金协会倡导金融机构及其第三方合作机构承诺不采用不正当方式，诱导消费者过度消费或接受超出其风险认知和还款能力的借贷。在推荐相关金融产品服务时，应保障消费者的知情权和自主选择权，明示贷款主体、年化综合成本、还本付息安排、违约责任、逾期清收以及咨询投诉渠道基本信息。</p>
          <div class="news-bg-img"  id="newIcon2"></div>
          <p>作为一家有温度的金融平台，易借速贷严格执行国家相关法规和政策，积极参加行业交流，促进行业自律。同时，尊重消费者使用信贷产品的真实意愿，不做强制交易，保障每一位用户的合法权益。</p>
          <p>目前，易借速贷母公司未讯科技已与锡商银行、大兴安岭银行、马上消费金融等金融机构展开深度合作，所有放款资金均来自金融机构。同时，易借速贷也已全面接入银行征信系统，并与多家第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，保护用户信息安全。易借速贷期望通过科技+金融的完美结合，为平台所有用户提供普惠、绿色、可持续的金融服务。</p>
          `
        },
        '易借速贷：倡导理性消费，打造更有温度的品牌': {
          html: `
            <h1>易借速贷：倡导理性消费，打造更有温度的品牌</h1>
            <h3>2021年06月08日 10:35</h3>
            <p>随着经济快速发展，人们的消费观念也在不断发生变化，消费结构改善优化，尤其在后疫情时代，国民消费已成为国内经济稳定发展的基石。但在促进消费的同时，社会上也出现了金钱攀比、鼓吹无限制消费等不良现象，尤其在年轻人中间，炫富的风气蔓延。在众多信息诱惑下，年轻人甚至不惜透支消费能力，透支自己的信用，这不但会造成个人的信用危机，也为各种金融诈骗提供了契机，长此以往更是会影响到金融安全。</p>
            <h2>量入为出，适度消费</h2>
            <p>央行明确指出“不宜依赖消费金融扩大消费”，《人民日报》也呼吁各大消费信贷机构应该承担起金融消费者权益保护责任，合理倡导理性消费，适度借贷。避免盲目消费，在自己能够承受的范围内购买物有所值、经济实用的商品，把钱花在关键的地方，不与周围的人进行攀比，不要浪费金钱。适度消费并不是降低物质生活水平，而是提高生活效率，倡导可持续的生活，当一个理性的消费者。</p>
            <div class="news-bg-img"  id="newIcon3"></div>
            <h2>选择正规平台，远离网贷陷阱</h2>
            <p>在国家号召的背景下，各大金融机构率先发声，积极响应，提倡理性消费，未讯科技旗下的易借速贷也是其中之一。易借速贷是受国家监管的正规金融科技公司，自成立之初，严格执行国家相关法规和政策，积极参加行业交流，支持并深入与监管机构合作，为用户提供高效、绿色、可持续的金融服务，通过科技能力的不断提升和风控能力的加强，不断降低成本，帮助金融机构实现金融的普惠性。易借速贷除了积极宣传反诈知识，还以科技为武器，筑起风控围墙，在打击金融诈骗，预防金融风险上做出自己的努力。</p>
            <p>易借速贷始终认为，金融科技公司应在提高公众金融素质、促进金融生态建设、服务百姓生活上起到正面作用。从企业责任的角度出发，传递的正确消费观，助力信贷业务健康发展，让消费者认识到金融的实际意义，选择正规的信贷平台，避开各种网贷陷阱，让消费者感受到到金融其实也可以很暖心。</p>
            <p>易借速贷将与消费者和社会各界一起努力，倡导更适合消费者的普惠消费观，打造更有温度的金融品牌形象。</p>
            `
        },
        '易借速贷以安全为基，筑建信贷风控防护墙': {
          html: `
            <h1>易借速贷以安全为基，筑建信贷风控防护墙</h1>
            <h3>2021年05月27日 17:04</h3>
            <p>最近几年，互联网金融进入高速发展时期，经历了野蛮生长期之后，互联网金融开始进入严格的全面监管期。随着各地监管细则陆续出台，国家监管部门对互联网金融的支持也转入到实际行动中，为互联网金融提供了切实的政策依据，助力互联网金融向着规范化、绿色可持续化的良性方向发展。</p>
            <p>如今，各种政策指标纷纷落实，互联网金融行业正式进入洗牌期，各种实力不足，风控不达标的平台逐渐退出金融行业。当潮水退去后，真正的安全、高效、保障用户利益、值得信赖的金融平台开始彰显实力。</p>
            <div class="news-bg-img"  id="newIcon4"></div>
            <p>未讯科技，多年来一直深耕金融科技领域，恪守普惠金融的理念，为广大用户提供真正安全、可靠、高效的优质金融服务。旗下金融科技平台易借速贷，利用金融科技探索赋能产业的新模式，持续将先进技术融入普惠金融发展，同时也重视风控建设。</p>
            <p>未讯科技坚信，风控是平台的核心，无论业务拓展、运营效率的提升，还是风险管理改进等方面的突破都离不开完善的风控体系。易借速贷在借贷流程各个环节制定了安全严苛的审核门槛，采用顶级信息安全技术传输加密，专业的多级防火墙，符合国际顶级安全PCI 标准，使用技术预防恶意骗贷。在互联网大数据与AI智能科技的支撑下，易借速贷在反欺诈，信用评级，和降低成本提升效率方面效果显著。</p>
            <p>在金融与科技深度融合的过程中，风控体系也会越来越完善，易借速贷将持续强化前沿技术在智能风控体系中的应用，以安全为基，筑建信贷风控防护墙。未来，易借速贷将与金融机构以及广大互联网金融企业携手，为金融机构信贷业务数字化转型助力，共同迎接互联网金融有序化、规范化、可持续化时代的到来。</p>
            `
        },
        '易借速贷：聚焦科技发展，合作共赢新生态': {
          html: `
            <h1>易借速贷：聚焦科技发展，合作共赢新生态</h1>
            <h3>2021年05月19日 16:28</h3>
            <p>数字金融时代已加速到来，金融科技作为推动行业发展的新引擎，必将助力金融行业高质快速地实现数字化转型。未讯科技作为成熟的金融科技平台，致力于以科技推动金融服务业的数字化升级，携手合作伙伴为消费者提供普惠、绿色、可持续的服务。</p>
            <div class="news-bg-img"  id="newIcon5"></div>
            <h2>数据驱动，专于科技的金融科技平台</h2>
            <p>易借速贷作为未讯科技旗下的信贷服务品牌，充分利用自身的科技优势，始终恪守普惠金融的理念，利用金融科技探索赋能产业的新模式，持续将先进技术融入普惠金融发展，加强风险控制措施，保护客户合法权益，让更多的人体验到智慧金融带来的便利。</p>
            <p>易借速贷目前已实现了以技术助力金融机构全流程的数字化升级，这一结果来自未讯科技持续不断的技术投入。易借速贷采用顶级信息安全技术SSL传输加密，先进的存储加密机制，严格的授权与审计制度，专业的多级防火墙，多数据中心热备份实现数据容灾，所有信息全程安全加密，多重防护，符合国际顶级安全PCI 标准。同时，易借速贷严格遵照隐私条款保障用户信息安全，全链路数据加密脱敏，防止用户信息泄露，同时严格资金管理，全流程监控和保障用户账户安全，交易过程中放款安全。</p>
            <h2>科技赋能战略引领合作 携手共建实现行业共赢新生态</h2>
            <p>易借速贷现已携手哈喽单车、同程旅游、蘑菇街等，整合各方资源，在科技赋能、用户服务、产品创新等方面开展新的合作，易借速贷秉承开放、合作、共赢的发展理念，为消费者提供更为灵活的金融服务。</p>
            <p>在此前，易借速贷已与锡商银行、大兴安岭银行、马上消费金融等金融机构展开深度合作，所有放款资金均来自金融机构。并全面接入银行征信系统，所有用户的逾期情况都会在个人征信记录中体现。同时，易借速贷与第三方数据机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，在行业系统中披露失信信息。我们期望通过科技+金融的完美结合，为所有用户提供便捷、安心的信贷服务。</p>
          <p>数字经济作为构建新发展格局的重要战略方向，将是今后金融行业发展的重要源泉，科技在这一进程中将扮演着十分重要的角色。未讯科技将继续发力‘数据驱动决策’，发挥积累的先进科技经验，携手各合作方，共建合作共赢新生态，助力传统金融机构进行数字化升级。</p> `
        },
        '助力金融业规范健康发展，易借速贷全面纳入央行征信系统': {
          html: `
            <h1>助力金融业规范健康发展，易借速贷全面纳入央行征信系统</h1>
            <h3>2021-05-06 11:40</h3>
            <p>在金融科技飞速发展的今天，花呗、借呗、白条等各类消费金融衍生品层出不穷，给消费者带来种类繁多的信贷选择，个人信用的重要性也随之逐渐提升。在国家互联网信用体系建设不断推进的大背景下，互联网金融机构和消费金融机构也不断加强信用信息的共享与合作，支持互联网金融专项整治和互联网信用体系建设，防范金融风险，更好的服务用户。</p>
            <p>自成立之初，易借速贷就不断提升平台的风险管理水平，逐步实现与征信系统的对接，用户使用情况也逐步地被纳入信用报告的记录范围，自5月6日起，易借速贷将全面纳入央行征信系统。</p>
            <div class="news-bg-img"  id="newIcon6"></div>
            <p>在信贷审批过程中，个人信用报告对信贷决策有重要影响，但并不是借款就会影响征信，只要用户按时按期正常还款，就不会对个人信用记录产生不良影响。信用记录最基本的功能是了解验证他人的信用信息，并通过信用信息的传播来改善交易双方的信息不对称，起到约束交易双方行为的作用。拥有良好信用记录的借款人，不仅可以更快获得贷款，还能获得更高的额度，更低的利率。如果借款人有不良的信用记录，在信贷申请的过程中就有被拒绝的可能，甚至影响到房贷、车贷等。</p>
            <p>据悉，易借速贷此前已与锡商银行、大兴安岭银行、马上消费金融等金融机构展开深度合作，所有放款资金均来自金融机构。此次全面接入银行征信系统后，所有用户的逾期情况都会在个人征信记录中体现。同时，易借速贷与多家第三方数据公司、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，在行业系统中披露失信信息。期望通过科技+金融的完美结合，为所有用户提供便捷、安心的信贷服务。</p>
            <p>易借速贷恪守普惠金融的理念，利用金融科技探索赋能产业的新模式，持续将先进技术融入普惠金融发展，加强风险控制措施，保护客户合法权益，让更多的人体验到智慧金融带来的便利，助力金融业规范健康发展。</p>`
        },
        '易借速贷倡导绿色发展，用公益行动反哺社会': {
          html: `
            <h1>易借速贷倡导绿色发展，用公益行动反哺社会</h1>
            <h3>2021-04-22 16:47:08</h3>
            <p>对于一家互联网金融平台来说，倡导绿色可持续发展至关重要。只有在严格遵守国家法律法规的前提下谋求发展，才能成为一个令人信服的多元化的金融科技开放平台。易借速贷就是这样一个可以为广大用户提供快捷、安全、高效的互联网金融服务的平台。易借速贷通过技术创新不断发展和革新金融服务，有效保障金融科技下的信贷供给，构建可持续发展的生态金融圈。</p>
            <p>易借速贷的母公司是未讯科技，该公司深耕金融领域多年，将社会责任不断植入自身的商业模式，大力发展数字金融，通过打造绿色业务，深入社会服务，践行公益等积极反哺社会。易借速贷在母公司未讯科技的影响下，始终坚持绿色发展，并不断完善自身业务，积极寻求第三方权威机构的多方合作，完善信用体系建设，并用实际行动践行社会责任。疫情期间，易借速贷迅速启动应急响应机制，第一时间向多家医疗机构捐赠KN95口罩，并向全省中小企业捐赠100万只口罩，助力复工复产。</p>
            <div class="news-bg-img"  id="newIcon7"></div>
            <p>除了坚持绿色发展，践行公益活动之外，易借速贷也一直坚持以人为本，把人才看作是企业发展的基石。对于易借速贷来说，人才是企业发展过程中最高贵的资源。易借速贷坚持打造并维护包容、有序、多元化、公平公正的工作环境，强化人力资源管理，不断完善人员培养体系，帮助员工自我提升，同事通过绩效、能力、潜力等人才盘点方式，建立人才库，构建人才体系。</p>
            <p>与此同时，易借速贷还始终致力于以“数据驱动”为核心导向，专注于深度学习+人工智能结合应用，形成高效简单的金融服务，深度赋能产业，让产业得以更好的服务用户，形成更强的产业粘性，用科技助力金融行业可持续发展，有效的管理信贷风险，更好的为借款人和机构合作伙伴提供服务，构建和谐生态圈。</p>
            <p>并且，易借速贷还利用金融科技改进业务流程，快速满足用户的资金需求。目前，易借速贷打造的百凌金科大数据风控模型，已经实现高度自动化，不仅使合作伙伴受益，更推动了行业信用风险管控体系的完善与发展。</p>
            <p>在互联网浪潮中，网上借钱已经成了常态，相比传统金融服务，互联网金融具有高效、便捷等多种优势。但如果大家想要通过互联网金融平台享受借贷服务，还是要选择一家安全、专业的互联网金融平台。易借速贷就是一个很不错的选择。</p>`
        }, '明确年化利率，易借速贷拥抱数字经济潮流': {
          html: `
            <h1>明确年化利率，易借速贷拥抱数字经济潮流</h1>
            <h3>2021年04月12日 11:57</h3>
            <p>近日，央行发布新的贷款政策，为维护贷款市场竞争秩序，保护金融消费者的合法权益，要求在金融市场，所有机构的贷款产品应该明示贷款年化利率。央行的新政令放贷金融机构与借贷人之间的权利约束更加有效，尤其是借贷人的权益能得到最大限度的保护。</p>
            <p>不得不说，轮番的政策创造了更好的金融环境，也给有资质的合规企业提供了更健康的生存环境，更多的机会以及挑战。</p>
            <p>作为未讯科技旗下信贷产品，易借速贷上线以来凭借对金融信贷业务的理解逐步打造出效率高、体验好、放款快的金融产品，受到用户及行业的美誉。易借速贷借助科技手段不断提升借款用户的借款效率和用款体验，将先进的金融科技手段结合金融机构风控能力在用户筛选、风控建议、信用额度调整、贷后服务等各方面，在科技推动下快速成长，不断寻求更大的生长空间。</p>
            <div class="news-bg-img"  id="newIcon8"></div>
            <h2>新政下，数字化决胜未来</h2>
            <p>在愈来愈完善的金融监管之下，金融科技行业日趋规范化、标准化，行业集中度与确定性也不断提升，在金融行业数字化转型的大背景下，具备多年用户运营及技术经验的易借速贷，成立之初即以“数据驱动”为 核心导向， 专注于深度学习＋人工智能结合应用，形成高效简单的金融服务，深度赋能产业，让产业得以更好的服务用户， 形成更强的产业粘性。</p>
            <p>数字化的提升转变非一日之功，需要专注持续的投入。易借速贷母公司霖梓控股秉持数据驱动决策，金融赋能产业的战略理念，从成立就开始从0到1布局数字金融，并持续加大对大数据、人工智能、云计算等多个领域的研发投入，将前沿科技成果应用于流程业务实践中。易借速贷自主研发的百凌金科大数据平台，将数字化嵌入产品、渠道和流程中，应用在获客、风控、等各个方面，成为涵盖全业务流程、全生命周期的核心技术系统。同时与国内主流数据方合作，建立开放共享的金融服务生态圈，从信息化向数字化、智能化、生态化迈进。</p>
            <p>在未来，易借速贷还将进一步加大信息科技投入，拥抱数字经济潮流，充分发挥金融科技的赋能效应，继续加大力度布局产业数字化，通过提升全面数字化闭环能力，助力金融行业发展，为用户提供便捷高效、个性化、有温度的金融服务，迈向高质量发展之路。</p>`
        }

      }
    }
  },
  watch: {},
  props: {
    newTitle: {
      type: String,
      default () {
        return ''
      }
    }
  },
  mounted () {
    this.showNews = this.newsMap[this.newTitle]
  },
  updated () {
    // let img = document.getElementsByClassName('news-bg-img')[0]
    // img.style.backgroundImage = "url('/static/new1.jpg')"
    // console.log(img);
  },
  methods: {
    limitWords (txt) {
      var str = txt;
      str = str.substr(0, 6) + '......';
      return str;
    }
  },
  components: {
    Header,
    Footer,
    VHTML
  }
}
</script>

<style lang="less">
.main-news {
  width: 867px;
  margin: 0px auto;
  .nav {
    display: flex;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    align-items: center;
    height: 60px;
    font-size: 16px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    font-weight: 400;
    color: rgba(44, 49, 67, 0.7);
    line-height: 22px;
    &-back {
      cursor: pointer;
    }
    &-back:hover {
      cursor: pointer;
    }
    img {
      width: 24px;
      height: 24px;
    }
    p {
      color: #ee6c28;
    }
  }
  .news-details {
    margin-top: 40px;
    margin-bottom: 65px;
    h1 {
      font-size: 30px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
      font-weight: 500;
      color: #1e1f20;
      line-height: 42px;
    }
    h2 {
      margin-top: 15px;
      font-size: 20px;
      font-family: PingFang, PingFang-Bold;
      font-weight: 700;
      text-align: justify;
      color: #1e1f20;
      line-height: 32px;
    }
    h3 {
      margin-top: 8px;
      margin-bottom: 60px;
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: justify;
      color: #1e1f20;
      line-height: 32px;
      margin-bottom: 35px;
    }
    .news-bg-img {
      width: 866px;
      height: 477px;
      margin-top: 34px;
      margin-bottom: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    #newIcon30-1 {
      background-image: url("~@/assets/img/pc/newsInfo/new30-1.jpg");
    }
    #newIcon14-4 {
      background-image: url("~@/assets/img/pc/newsInfo/new14-4.png");
    }
    #newIcon14-3 {
      background-image: url("~@/assets/img/pc/newsInfo/new14-3.png");
    }
    #newIcon14-2 {
      background-image: url("~@/assets/img/pc/newsInfo/new14-2.png");
    }
    #newIcon14-1 {
      background-image: url("~@/assets/img/pc/newsInfo/new14-1.png");
    }
    #newIcon14 {
      background-image: url("~@/assets/img/pc/newsInfo/new14.png");
    }
    #newIcon13 {
      background-image: url("~@/assets/img/pc/newsInfo/new13.png");
    }
    #newIcon12 {
      background-image: url("~@/assets/img/pc/newsInfo/new12.png");
    }
    #newIcon11-1 {
      background-image: url("~@/assets/img/pc/newsInfo/new11-1.png");
    }
    #newIcon11 {
      background-image: url("~@/assets/img/pc/newsInfo/new11.png");
    }
    #newIcon10 {
      background-image: url("~@/assets/img/pc/newsInfo/new10.png");
    }
    #newIcon9 {
      background-image: url("~@/assets/img/pc/newsInfo/new9.png");
    }
    #newIcon1 {
      background-image: url("~@/assets/img/pc/newsInfo/new1.jpg");
    }
    #newIcon2 {
      background-image: url("~@/assets/img/pc/newsInfo/new2.jpg");
    }
    #newIcon3 {
      background-image: url("~@/assets/img/pc/newsInfo/new3.jpg");
    }
    #newIcon4 {
      background-image: url("~@/assets/img/pc/newsInfo/new4.jpg");
    }
    #newIcon5 {
      background-image: url("~@/assets/img/pc/newsInfo/new5.jpg");
    }
    #newIcon6 {
      background-image: url("~@/assets/img/pc/newsInfo/new6.jpg");
    }
    #newIcon7 {
      background-image: url("~@/assets/img/pc/newsInfo/new7.jpg");
    }
    #newIcon8 {
      background-image: url("~@/assets/img/pc/newsInfo/new8.jpg");
    }
  }
}
</style>